var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"main-title"},[_vm._v("会员设置")]),_c('div',{staticClass:"detail-info"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"100px","size":"mini"}},[_c('el-form-item',{attrs:{"label":"名称: ","label-position":"left","prop":"name","rules":[
              { required: true, message: '名称不能为空'},
            ]}},[_c('el-input',{staticStyle:{"width":"200px"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"有效天数: ","label-position":"left","prop":"days","rules":[
              { required: true, message: '有效天数'},
            ]}},[_c('el-input-number',{attrs:{"min":1},model:{value:(_vm.form.days),callback:function ($$v) {_vm.$set(_vm.form, "days", $$v)},expression:"form.days"}})],1),_c('el-form-item',{attrs:{"label":"价格: ","label-position":"left","prop":"price","rules":[
              { required: true, message: '价格'},
            ]}},[_c('el-input-number',{attrs:{"min":1},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('el-form-item',{attrs:{"label":"封面：","label-position":"left","prop":"link","rules":[
              { required: true, message: '封面'},
            ]}},[_c('edit-cover',{attrs:{"pic":_vm.imageUrl + _vm.form.link,"uploadPath":_vm.uploadPath},on:{"success":_vm.uploadSuccess}})],1),_c('el-form-item',{attrs:{"label":"内容：","label-position":"left"}},[_c('editor',{attrs:{"content":_vm.form.detail},on:{"onChange":_vm.onChange}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small","type":"primary","loading":_vm.buttonLoading},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 取消 ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }